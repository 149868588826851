import Axios from "axios";
import React, { Fragment, useContext, useEffect, useState } from "react";
import cross from "../../Assets/Images/cancel.png";
import downarrow from "../../Assets/Images/down-arrow.svg";
import uparrow from "../../Assets/Images/up-arrow.svg";
import { ProjectContext } from "../../Context/MainContext";
import Backdrop from "../Backdrop/Backdrop";
import { Form, Label, Wrapper } from "../Contact/Contact";
import ReactScrollWheelHandler from "react-scroll-wheel-handler";
import Done from "../Done/Done";

import {
  Alignbtn,
  Bottomarrow,
  Button,
  Content,
  Cross,
  Inline,
  Stepcircule,
  Stepline,
  Svg,
  Text,
  Toparrow,
  Wrap,
  Wrapstep,
} from "../Styled/styled";
import { applydata } from "./applydata";
import styled from "styled-components";


const Columns = styled.div`
  display: flex;

  @media (max-width: 1366px) {
    flex-direction: column;
  }
`;

const Column = styled.div`
 flex-grow: 1;
 flex-basis: 100%;
 margin-right: 3%;
`;

let ITEMS = 5;
const Modal = ({ showApply, show }) => {
  const [index, setIndex] = useState(0);
  const { hideModal, setHideButton } = useContext(ProjectContext);

  const [formSend, setFormSend] = useState(
    sessionStorage.getItem("hiresubmitform") || false
  );
  const [isDisableArrow, setIsDisableArrow] = useState(true);

  const [dataMap, setDataMap] = useState([]);
  let circule = [];
  for (let i = 0; i < applydata.length + 1; i++) {
    circule.push(<Stepcircule current={index + 1 > i} key={i} />);
  }

  const handleUp = (e) => {
    setIndex(index - 1);
  };

  const handleDown = (e) => {
    if (isDisableArrow) {
      if (applydata[index].valid) {
        applydata.length > index && setIndex(index + 1);
      }
    } else {
      applydata.length > index && setIndex(index + 1);
    }
  };

  const findChecked = (id) => {
    const found = applydata.find((el) => el.id === id);
    return found.checked;
  };

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [web, setWeb] = useState("");
  const [desc, setDesc] = useState("");
  const [phone, setPhone] = useState("");
  const [location, setLocation] = useState("");

  const [errorMode, setErrorMode] = useState(false);
  const [firstError, setFirstError] = useState(false);

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (index > 2) {
      return;
    } else {
      if (!applydata[index].loaded) {
        setLoading(true);
      }
      if (applydata[index].loaded) {
        setDataMap(applydata[index].options);
        setLoading(false);
      } else {
        const name = applydata[index].name;
        Axios.get(`https://marketingmap.brainster.co/api/${name}`).then(
          (res) => {
            applydata[index].options = res.data;
            applydata[index].loaded = true;
            setLoading(false);
            setDataMap(applydata[index].options);
          }
        );
      }
    }
    if (applydata[index].valid) {
      setIsDisableArrow(false);
    } else {
      setIsDisableArrow(true);
    }
  }, [index]);

  useEffect(() => {
    function keydown(e) {
      e.preventDefault();
      if (!isDisableArrow && e.keyCode === 40) {
        handleDown(e);
      }
    }

    applydata.length > index &&
      show &&
      document.addEventListener("keydown", keydown);

    return () => {
      document.removeEventListener("keydown", keydown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index, applydata.length, show, isDisableArrow]);

  useEffect(() => {
    function keyup(e) {
      e.preventDefault();
      if (e.keyCode === 38) {
        handleUp(e);
      }
    }

    index !== 0 &&
      show &&
      index !== applydata.length &&
      document.addEventListener("keydown", keyup);

    return () => {
      document.removeEventListener("keydown", keyup);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index, show, applydata.length]);

  const Popup = (
    <div>
      <Backdrop showbackdrop={show} clicked={hideModal} />
      <Wrap open={show}>
        {!formSend ? (
          <Wrapstep
            circule={index}
            wrapstepheightmob={window.innerWidth < 426 ? "130px" : "240px"}
            wrapsteptopmob="0%"
          >
            <Fragment>
              {circule}
              <Stepline multypop={index} />
            </Fragment>
          </Wrapstep>
        ) : null}
        <Cross>
          <img
            onClick={() => {
              hideModal();
              setHideButton(false);
            }}
            src={cross}
            alt="cross"
          />
        </Cross>
        {!formSend ? (
          errorMode ? (
            <>
            <h1 className="err-msg err-sm">{ firstError || "Настана грешка, обидете се повторно!"}</h1>
            </>
          ) : (
            <Fragment>
              <Toparrow showtop={index === 0} onClick={() => handleUp()}>
                <Svg path={uparrow} id="Capa_1" />
              </Toparrow>
              <Bottomarrow
                showbottom={applydata.length === index}
                onClick={() => handleDown()}
              >
                <Svg disabled={isDisableArrow} path={downarrow} id="Capa_1" />
              </Bottomarrow>
            </Fragment>
          )
        ) : null}
        {!formSend ? (
          <Content
            conwidth={formSend ? "55%" : undefined}
            content={index === 1 || index === 2 || ITEMS > 5}
            zeromt={index === 1 ? "4rem" : index === 2 ? "7rem" : "-1.5rem"}
          >
            {index < 3 ? (
              <Fragment>
                <h2>{applydata[index].title}</h2>
                <p className="require">{applydata[index].require}</p>
                <p className="note">{applydata[index].note}</p>
                <Text
                  textheight={index === 1 || index === 2 ? "260px" : "auto"}
                  mobheight={index === 1 || index === 2 ? "210px" : "auto"}
                  wrap="wrap"
                >
                  {loading ? (
                    <div className="spin"></div>
                  ) : (
                    applydata[index].options.length > 1 &&
                    dataMap.length &&
                    dataMap.map((el) => {
                      const values = Object.values(el);
                      if (index !== 0) {
                        return (
                          <Inline
                            key={el.id}
                            margin={
                              index === 1 || index === 2
                                ? "0 50px 5px 0"
                                : "0 0 .5rem 0"
                            }
                            mr={ITEMS > 5 ? 0 : undefined}
                          >
                            <input
                              type="checkbox"
                              id={el.id}
                              name={applydata[index].name}
                              value={values[1]}
                              checked={
                                applydata[index].checked.includes(el.id)
                                  ? true
                                  : null
                              }
                              onChange={(e) => {
                                if (applydata[index].checked.includes(el.id)) {
                                  applydata[index].checked.filter(
                                    (el) => e.currentTarget.id !== el.id
                                  );
                                  e.currentTarget.checked = false;
                                } else {
                                  applydata[index].checked.push(el.id);
                                  e.currentTarget.checked = true;
                                  applydata[index].valid = true;
                                  setIsDisableArrow(false);
                                }
                              }}
                            />
                            <label htmlFor={el.id}>{values[1]}</label>
                          </Inline>
                        );
                      } else {
                        return (
                          <Inline
                            key={el.id}
                            margin={
                              index === 3 ? "0 50px 5px 0" : "0 0 .5rem 0"
                            }
                            mr={ITEMS > 5 ? 0 : undefined}
                          >
                            <input
                              type="radio"
                              id={el.id}
                              name={applydata[index].name}
                              value={values[1]}
                              checked={
                                el.id === applydata[index].checked ? true : null
                              }
                              onChange={(e) => {
                                applydata[index].checked = el.id;
                                applydata[index].valid = true;
                                setIsDisableArrow(false);
                                // e.currentTarget.checked = true;
                              }}
                            />
                            <label htmlFor={el.id}>{values[1]}</label>
                          </Inline>
                        );
                      }
                    })
                  )}
                </Text>
              </Fragment>
            ) : (
              <Wrapper>
                <Fragment>
                  {" "}
                  <h2 className="cheading">Речиси готово!</h2>
                  <Form>
                    <Columns>
                    <Column>
                    <Label htmlFor="name">
                      Вашето целосно име *
                      <input
                        type="text"
                        name="name"
                        id="name"
                        required
                        value={name}
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                        // onBlur={blur}
                        // onFocus={focus}
                      ></input>
                    </Label>
                    <Label htmlFor="desc">
                      Краток опис за вас *
                      <input
                        type="text"
                        name="desc"
                        id="desc"
                        required
                        value={desc}
                        onChange={(e) => {
                          setDesc(e.target.value);
                        }}
                        // onBlur={blur}
                        // onFocus={focus}
                      ></input>
                    </Label>

                    <Label htmlFor="email">
                      Внесете ја вашата email адреса *
                      <input
                        type="email"
                        name="email"
                        id="email"
                        value={email}
                        required
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        // onBlur={blur}
                        // onFocus={focus}
                        placeholder="example@email.com"
                      ></input>
                    </Label>
                    </Column>
                    <Column>
                    <Label htmlFor="web">
                      Линк до ваш профил од социјална мрежа *
                      <input
                        type="text"
                        name="web"
                        id="web"
                        value={web}
                        required
                        onChange={(e) => {
                          setWeb(e.target.value);
                        }}
                        // onBlur={blur}
                        // onFocus={focus}
                      ></input>
                    </Label>
                    <Label htmlFor="phone">
                      Вашиот телефонски број *
                      <input
                        type="text"
                        name="phone"
                        id="phone"
                        required
                        value={phone}
                        onChange={(e) => {
                          setPhone(e.target.value);
                        }}
                        placeholder="мин. 9 бројки"
                        // onBlur={blur}
                        // onFocus={focus}
                      ></input>
                    </Label>
                    <Label htmlFor="location">
                      Каде сте стационирани? *
                      <input
                        type="text"
                        name="location"
                        id="location"
                        value={location}
                        required
                        onChange={(e) => {
                          setLocation(e.target.value);
                        }}
                        // onBlur={blur}
                        // onFocus={focus}
                      ></input>
                    </Label>
                    </Column>
                    </Columns>
                    <Alignbtn>
                      <Button
                        onClick={(e) => {
                          if (
                            !web ||
                            !email ||
                            !name ||
                            email.indexOf("@") === -1
                          ) {
                            return;
                          } else {
                            e.preventDefault();

                            Axios({
                              method: "post",
                              responseType: "json",
                              url:
                                "https://marketingmap.brainster.co/api/applicant/store",
                              data: {
                                marketing_partner_id: findChecked(1),
                                name: name,
                                email: email,
                                location: location,
                                link: web,
                                phone_number: phone,
                                description: desc,
                                experiences: findChecked(3),
                                marketing_services: findChecked(2),
                              },
                            })
                              .then((response) => {
                                console.log(response);
                                setFormSend(true);
                                setFirstError(false);
                                setErrorMode(false);
                              })
                              .catch((error) => {
                                // console.log(error.response);
                                setErrorMode(true);
                                let errors = error.response.data.errors;
                                let firsterror = errors[Object.keys(errors)[0]]
                                setFirstError(firsterror)
                              });
                          }
                        }}
                      >
                        Потврди
                      </Button>
                    </Alignbtn>
                  </Form>
                </Fragment>
                {/* {showSpinner ? <div></div> : } */}
              </Wrapper>
            )}
          </Content>
        ) : (
          <Done />
        )}
      </Wrap>
    </div>
  );

  return (
    <ReactScrollWheelHandler
      upHandler={index > 0 && handleUp}
      downHandler={!isDisableArrow && handleDown}
      leftHandler={!isDisableArrow && handleDown}
      rightHandler={index > 0 && handleUp}
    >
      {Popup}
    </ReactScrollWheelHandler>
  );
};

export default Modal;
