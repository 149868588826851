// import { Link } from "@reach/router";
import React, { useContext, useState } from "react";
import { ProjectContext } from "../../Context/MainContext";
import logo from "../img/Group 2.png";
import ApplyAsMarketer from "./ApplyAsMarketer";
import HireMarketer from "./HireMarketer";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

import "./Nav.css";

function Nav() {
  const { hideButton, showApply, showHire, hideLogo, setHideLogo } = useContext(
    ProjectContext
  );
  return (
    <React.Fragment>
      {!hideButton && (
        <header className="header">
          {hideLogo === false && (
            <a href="/" className={"logo"}>
              <img src={logo} alt="" />
            </a>
          )}

          <input className="menu-btn" type="checkbox" id="menu-btn" />
          <label
            className="menu-icon"
            htmlFor="menu-btn"
            onClick={() => {
              if (hideLogo) {
                setHideLogo(false);
              } else {
                setHideLogo(true);
              }
              // setHideLogo(!hideLogo);
            }}
          >
            <span className="navicon" />
          </label>
          <ul className="menu">
            <li>
              <Link to="marketing">Маркетинг Мапа</Link>
            </li>
            <li>
              <a href="https://brainster.co/" target="_blank" rel="noreferrer">
                Маркетинг Академија
              </a>
            </li>
            <div className="desktop-menu">
              <HireMarketer showHire={showHire} />
              <ApplyAsMarketer showApply={showApply} />
            </div>
            <div className="mobile-menu">
              <ApplyAsMarketer showHire={showHire} />
              <HireMarketer showApply={showApply} />
            </div>
          </ul>
        </header>
      )}
    </React.Fragment>
  );
}

export default Nav;
