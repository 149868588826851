import { useContext } from "react";
import { ProjectContext } from "../../Context/MainContext";
import "./HireMarketer.css";

function HireMarketer() {
  const { setShowHire, setHideButton, mobileMode } = useContext(ProjectContext);
  return (
    <div>
      <li
        className="hire-marketer hire-marketer-mobile"
        onClick={(e) => {
          setShowHire(true);
          if (mobileMode) setHideButton(true);

          e.preventDefault();
        }}
      >
        <a href="/">Ангажирај Маркетер</a>
      </li>
    </div>
  );
}
export default HireMarketer;
