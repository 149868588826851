import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext } from "react";
import categories from "../../Assets/JS/categories";
import { ProjectContext } from "../../Context/MainContext";
import FilterButtons from "../FilterButtons/FilterButtons";

const FiltersPC = () => {
  const {
    freelancer,
    company,
    allToggled,
    hideButton,
    handleFilter,
    handleTypes,
    showSpinner,
    services,
  } = useContext(ProjectContext);

  const checkmarkl = (
    <FontAwesomeIcon
      icon={faCheck}
      className={showSpinner ? "graycheck" : "checkmark"}
    />
  );

  return (
    <React.Fragment>
      {!hideButton && (
        <aside className="sidebar">
          <div className="sidebar-filters">
            <h3 className={showSpinner ? "grayh3" : ""}>Филтрираj</h3>
            <div className="filter-header">
              <div
                className="filterButton btnBB"
                onClick={() => {
                  handleTypes(1);
                }}
              >
                <div className="box-sm">{freelancer && checkmarkl}</div>
                <button disabled={showSpinner ? true : false}>
                  Маркетери фриленсери
                </button>
              </div>
              <div
                className="filterButton btnBB"
                onClick={() => {
                  handleTypes(2);
                }}
              >
                <div className="box-sm">{company && checkmarkl}</div>
                <button disabled={showSpinner ? true : false}>
                  Маркетинг агенции
                </button>
              </div>
            </div>
            <div className="filter-buttons">
              <div
                className="filterButton"
                onClick={() => {
                  handleFilter("ALL");
                }}
              >
                <div className="box-sm">{allToggled && checkmarkl}</div>
                <button disabled={showSpinner ? true : false}>
                  Прикажи ги сите
                </button>
              </div>
              {services.map((el, i) => {
                if (el.is_filter === 0) {
                  return false;
                } else {
                  return (
                    <FilterButtons
                      category={el.name}
                      checked={el.checked}
                      key={i}
                      catID={i + 1}
                    />
                  );
                }
              })}
            </div>
          </div>
          <div className="map-circle"></div>
        </aside>
      )}
    </React.Fragment>
  );
};

export default FiltersPC;
