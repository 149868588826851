let steps = [
  {
    id: "2",
    num: "num1",
    title: "Сподели ги твоите цели",
    text:
      "Нашиот тим ќе ги разгледа вашите маркетинг цели, буџетот и преференциите",
    button: "none",
    img: "imgslide1",
  },
  {
    id: "3",
    num: "num2",

    title: "Поврзување со маркетинг агенција или фриленсер",
    text:
      "Ќе ве поврземе со агенцијата или фриленсерот кој најмногу ви одговара",
    button: "none",
    img: "imgslide2",
  },
  {
    id: "4",
    num: "num3",
    title: "Ангажирај",
    text:
      "Оваа обрска е бесплатна. Немате никаква обврска да ангажирате преку нас",
    button: "buttonHireMarketier",
    img: "imgslide3",
  },
];

export default steps;
