import Axios from "axios";
import React, { Fragment, useContext, useEffect, useState } from "react";
import cross from "../../Assets/Images/cancel.png";
import downarrow from "../../Assets/Images/down-arrow.svg";
import uparrow from "../../Assets/Images/up-arrow.svg";
import { ProjectContext } from "../../Context/MainContext";
import Backdrop from "../Backdrop/Backdrop";
import { Form, Label, Wrapper } from "../Contact/Contact";
import ReactScrollWheelHandler from "react-scroll-wheel-handler";
import Done from "../Done/Done";

import {
  Alignbtn,
  Bottomarrow,
  Button,
  Content,
  Cross,
  Inline,
  Stepcircule,
  Stepline,
  Svg,
  Text,
  Toparrow,
  Wrap,
  Wrapstep,
} from "../Styled/styled";
import { hireData } from "./hiredata";

let ITEMS = 5;
const Popup = ({ showHire, show }) => {
  const [index, setIndex] = useState(0);
  const { hideModal, setHideButton } = useContext(ProjectContext);

  const [formSend, setFormSend] = useState(
    sessionStorage.getItem("hiresubmitform") || false
  );
  const [isDisableArrow, setIsDisableArrow] = useState(true);

  const [dataMap, setDataMap] = useState([]);
  let circule = [];
  for (let i = 0; i < hireData.length + 1; i++) {
    circule.push(<Stepcircule current={index + 1 > i} key={i} />);
  }

  const handleUp = (e) => {
    setIndex(index - 1);
  };

  const handleDown = (e) => {
    if (isDisableArrow) {
      if (hireData[index].valid) {
        hireData.length > index && setIndex(index + 1);
      }
    } else {
      hireData.length > index && setIndex(index + 1);
    }
  };

  const findChecked = (id) => {
    const found = hireData.find((el) => el.id === id);
    return found.checked;
  };

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [web, setWeb] = useState("");

  const [errorMode, setErrorMode] = useState(false);
  const [firstError, setFirstError] = useState(false)
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (index > 6) {
      return;
    } else {
      if (!hireData[index].loaded) {
        setLoading(true);
      }
      if (hireData[index].loaded) {
        setDataMap(hireData[index].options);
        setLoading(false);
      } else {
        const name = hireData[index].name;
        Axios.get(`https://marketingmap.brainster.co/api/${name}`).then(
          (res) => {
            hireData[index].options = res.data;
            hireData[index].loaded = true;
            setLoading(false);
            setDataMap(hireData[index].options);
          }
        );
      }
    }
    if (hireData[index].valid) {
      setIsDisableArrow(false);
    } else {
      setIsDisableArrow(true);
    }
  }, [index]);

  useEffect(() => {
    function keydown(e) {
      e.preventDefault();
      if (!isDisableArrow && e.keyCode === 40) {
        handleDown(e);
      }
    }

    hireData.length > index &&
      show &&
      document.addEventListener("keydown", keydown);

    return () => {
      document.removeEventListener("keydown", keydown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index, hireData.length, show, isDisableArrow]);

  useEffect(() => {
    function keyup(e) {
      e.preventDefault();
      if (e.keyCode === 38) {
        handleUp(e);
      }
    }

    index !== 0 &&
      show &&
      index !== hireData.length &&
      document.addEventListener("keydown", keyup);

    return () => {
      document.removeEventListener("keydown", keyup);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index, show, hireData.length]);

  const Popup = (
    <div>
      <Backdrop showbackdrop={show} clicked={hideModal} />
      <Wrap open={show}>
        {!formSend ? (
          <Wrapstep
            circule={index}
            wrapstepheightmob={window.innerWidth < 426 ? "300px" : "560px"}
            TOPMOB="-12%"
          >
            <Fragment>
              {circule}
              <Stepline multypop={index} HEIGHTBIG="300px" />
            </Fragment>
          </Wrapstep>
        ) : null}
        <Cross>
          <img
            onClick={() => {
              hideModal();
              setHideButton(false);
            }}
            src={cross}
            alt="cross"
          />
        </Cross>
        {!formSend ? (
          errorMode ? (
            <>
            <h1 className="err-msg">{ firstError || "Настана грешка, обидете се повторно!"}</h1>
            </>
          ) : (
            <Fragment>
              <Toparrow showtop={index === 0} onClick={() => handleUp()}>
                <Svg path={uparrow} id="Capa_1" />
              </Toparrow>
              <Bottomarrow
                showbottom={hireData.length === index}
                onClick={() => handleDown()}
              >
                <Svg disabled={isDisableArrow} path={downarrow} id="Capa_1" />
              </Bottomarrow>
            </Fragment>
          )
        ) : null}
        {!formSend ? (
          <Content
            conwidth={formSend ? "55%" : undefined}
            content={index === 3 && ITEMS > 5}
          >
            {index < 7 ? (
              <Fragment>
                <h2>{hireData[index].title}</h2>
                <p className="require">{hireData[index].require}</p>
                <p className="note">{hireData[index].note}</p>
                <Text
                  textheight={index === 3 ? "260px" : "auto"}
                  mobheight={index === 3 ? "210px" : "auto"}
                  wrap="wrap"
                >
                  {loading ? (
                    <div className="spin"></div>
                  ) : (
                    hireData[index].options.length > 1 &&
                    dataMap.length &&
                    dataMap.map((el) => {
                      const values = Object.values(el);
                      if (index === 3) {
                        return (
                          <Inline
                            key={el.id}
                            margin={
                              index === 4 ? "0 50px 5px 0" : "0 0 .5rem 0"
                            }
                            mr={ITEMS > 5 ? 0 : undefined}
                          >
                            <input
                              type="checkbox"
                              id={el.id}
                              name={hireData[index].name}
                              value={values[1]}
                              checked={
                                hireData[index].checked.includes(el.id)
                                  ? true
                                  : null
                              }
                              onChange={(e) => {
                                if (hireData[index].checked.includes(el.id)) {
                                  hireData[index].checked.filter(
                                    (el) => e.currentTarget.id !== el.id
                                  );
                                  e.currentTarget.checked = false;
                                } else {
                                  hireData[index].checked.push(el.id);
                                  e.currentTarget.checked = true;
                                  hireData[index].valid = true;
                                  setIsDisableArrow(false);
                                }
                              }}
                            />
                            <label htmlFor={el.id}>{values[1]}</label>
                          </Inline>
                        );
                      } else {
                        return (
                          <Inline
                            key={el.id}
                            margin={
                              index === 3 ? "0 50px 5px 0" : "0 0 .5rem 0"
                            }
                            mr={ITEMS > 5 ? 0 : undefined}
                          >
                            <input
                              type="radio"
                              id={el.id}
                              name={hireData[index].name}
                              value={values[1]}
                              checked={
                                el.id === hireData[index].checked ? true : null
                              }
                              onChange={(e) => {
                                hireData[index].checked = el.id;
                                hireData[index].valid = true;
                                setIsDisableArrow(false);
                                // e.currentTarget.checked = true;
                              }}
                            />
                            <label htmlFor={el.id}>{values[1]}</label>
                          </Inline>
                        );
                      }
                    })
                  )}
                </Text>
              </Fragment>
            ) : (
              <Wrapper>
                <Fragment>
                  {" "}
                  <h2 className="cheading">Речиси готово!</h2>
                  <Form>
                    <Label htmlFor="name">
                      Вашето целосно име / името на вашата компанија *
                      <input
                        type="text"
                        name="name"
                        id="name"
                        required
                        value={name}
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                        // onBlur={blur}
                        // onFocus={focus}
                      ></input>
                    </Label>
                    <Label htmlFor="email">
                      Внесете ја вашата email адреса *
                      <input
                        type="email"
                        name="email"
                        id="email"
                        value={email}
                        required
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        placeholder="example@email.com"
                        // onBlur={blur}
                        // onFocus={focus}
                      ></input>
                    </Label>
                    <Label htmlFor="web">
                      Внесете ја вашата веб сајт адреса *
                      <input
                        type="text"
                        name="web"
                        id="web"
                        value={web}
                        required
                        onChange={(e) => {
                          setWeb(e.target.value);
                        }}
                        // onBlur={blur}
                        // onFocus={focus}
                      ></input>
                    </Label>
                    <Alignbtn>
                      <Button
                        onClick={(e) => {
                          if (
                            !web ||
                            !email ||
                            !name ||
                            email.indexOf("@") === -1
                          ) {
                            return;
                          } else {
                            e.preventDefault();
                            Axios({
                              method: "post",
                              responseType: "json",
                              url:
                                "https://marketingmap.brainster.co/api/employer/store",
                              data: {
                                company_name: name,
                                website: web,
                                email: email,
                                marketing_partner_id: findChecked(3),
                                marketing_services: findChecked(4),
                                industry_id: findChecked(1),
                                employees_number_id: findChecked(2),
                                budget_id: findChecked(5),
                                realisation_time_id: findChecked(6),
                                searching_stage_id: findChecked(7),
                              },
                            })
                              .then((response) => {
                                setFormSend(true);
                                setErrorMode(false);
                                setFirstError(false)
                              })
                              .catch((error) => {
                                // console.log(error.response);
                                setErrorMode(true);
                                let errors = error.response.data.errors;
                                let firsterror = errors[Object.keys(errors)[0]]
                                setFirstError(firsterror)
                              });
                          }
                        }}
                      >
                        Потврди
                      </Button>
                    </Alignbtn>
                  </Form>
                </Fragment>
                {/* {showSpinner ? <div></div> : } */}
              </Wrapper>
            )}
          </Content>
        ) : (
          <Done />
        )}
      </Wrap>
    </div>
  );

  return (
    <ReactScrollWheelHandler
      upHandler={index > 0 && handleUp}
      downHandler={!isDisableArrow && handleDown}
      leftHandler={!isDisableArrow && handleDown}
      rightHandler={index > 0 && handleUp}
    >
      {Popup}
    </ReactScrollWheelHandler>
  );
};

export default Popup;
