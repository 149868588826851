import React, { useContext } from "react";
import { ProjectContext } from "../../Context/MainContext";

const SlidePage = (props) => {
  const { setShowHire } = useContext(ProjectContext);

  return (
    <div className={props.img}>
      <div className="gradientBackground">
        <h1 className="slideshow">
          <span className="fixedtext">Платформата во 3 чекори </span>
          <br />
          {props.title}{" "}
        </h1>
        <p className="slideshow stepsText"> {props.text} </p>
        <button onClick={() => setShowHire(true)} className={props.button}>
          Ангажирај маркетер
        </button>
      </div>
    </div>
  );
};

export default SlidePage;
