import React, { useContext } from "react";
import { ProjectContext } from "../../Context/MainContext";
import fbicon from "./facebook.png";
import "./Footer.css";
import instaicon from "./instagram.png";
import linkedinicon from "./linkedin.png";
import twittericon from "./twitter.png";
import { Link } from "react-router-dom";

function Footer({ showHire, showApply }) {
  const { setShowApply, setShowHire, mobileMode, inMap } = useContext(
    ProjectContext
  );
  return (
    <footer>
      <div className={`footer ${inMap && "footer-padding-down"}`}>
        <div className="title-footer">
          <h1>Поврзи се {mobileMode ? null : <br />} сега.</h1>
        </div>
        <div className="display-flex">
          <div className="social-icon">
            <ul>
              <li>
                <a
                  href="https://www.facebook.com/brainster.co/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={fbicon} alt="fbIcon" className="fbIcon" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/brainsterco/?hl=en"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={instaicon} alt="instaIcon" className="instaIcon" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/school/brainster-co/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={linkedinicon}
                    alt="linkedinIcon"
                    className="linkedinIcon"
                  />
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/Brainster_CPH"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={twittericon} alt="twitterIcon" />{" "}
                </a>
              </li>
            </ul>
          </div>
          <div className="social-media">
            <ul>
              <li>
                <Link to="/marketing">Маркетинг Мапа</Link>
              </li>
              <li>
                {" "}
                <a
                  href="https://brainster.co/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Маркетинг Академија
                </a>
              </li>
              <li
                className=" footermarketer-pc "
                onClick={() => setShowHire(true)}
              >
                <a to="" id="footermarketer">
                  Ангажирај Mаркетер
                </a>{" "}
              </li>
              <li
                className="footerapliciraj-pc"
                onClick={() => setShowApply(true)}
              >
                {" "}
                <a to="" id="footerapliciraj ">
                  Аплицирај како Mаркетер
                </a>{" "}
              </li>
              <li
                className="footerapliciraj-tel"
                onClick={() => setShowApply(true)}
              >
                {" "}
                <a to="" id="footerapliciraj  ">
                  Аплицирај како Mаркетер
                </a>{" "}
              </li>
              <li
                className="footermarketer-tel"
                onClick={() => setShowHire(true)}
              >
                <a to="" id="footermarketer ">
                  Ангажирај Mаркетер
                </a>{" "}
              </li>
            </ul>
          </div>
          <div className="contact-info">
            <ul>
              <li>contact@brainster.co</li>
              <li>Васил Ѓорѓов бр.19</li>
            </ul>
          </div>
        </div>
        <div className="img1"></div>
      </div>
    </footer>
  );
}
export default Footer;
