import React, { useContext } from "react";
import { ProjectContext } from "../../Context/MainContext";

const CtaCard = ({ showHire }) => {
  const { setShowHire } = useContext(ProjectContext);
  return (
    <div className="ctacard">
      <h1>Потребна ти е помош?</h1>
      <p>
        Нашиот тим е секогаш тука за да ви помогне во вашата <br /> потрага за
        идеален соработник.
      </p>
      <button onClick={() => setShowHire(true)}>Ангажирај Маркетер</button>
    </div>
  );
};

export default CtaCard;
