import { faEnvelope, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef, useState } from "react";
import ReactCardFlip from "react-card-flip";
import "./Card.css";
const Card = ({ name, type, desc, email, link, exp, phone, loc }) => {
  const [isFlipped, setIsFlipped] = useState(false);
  const envelope = (
    <FontAwesomeIcon
      icon={faEnvelope}
      className="envelope"
      onClick={() => {
        setIsFlipped(true);
        scrollOnCard();
      }}
    />
  );
  const closecard = (
    <FontAwesomeIcon
      icon={faTimes}
      className="closecard"
      onClick={() => setIsFlipped(false)}
    />
  );

  const cardRef = useRef();

  const scrollOnCard = () => {
    cardRef.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "nearest",
    });
  };

  return (
    <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
      <div className="item front" ref={cardRef}>
        <div className="card-header">
          <img
            src={`https://eu.ui-avatars.com/api/?background=random&name=${name}$rounded=true`}
            alt="img"
          />
          <div className="card-desc">
            <h3>{name}</h3>
            <p>{desc}</p>
          </div>
        </div>
        <div className="allCats">
          {exp.map((el, i) => {
            if (i === exp.length - 1) {
              return <p key={i}>{el.name}</p>;
            } else {
              return <p key={i}>{el.name},</p>;
            }
          })}
        </div>
        <div className="frontBottom">
          <a href={link} target="_blank" rel="noreferrer">
            Дознај повеќе
          </a>
          {envelope}
        </div>
      </div>
      <div className="item back">
        <h3 className="user-name">{name}</h3>
        <p className="street">{loc}</p>
        <p className="ph">{phone}</p>
        <p className="contactemail">
          <a href={`mailto:${email}`}>{email}</a>
        </p>
        {closecard}
      </div>
    </ReactCardFlip>
  );
};

export default Card;
