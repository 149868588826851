import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import { ProjectContext } from "../../Context/MainContext";
import "./FilterButtons.css";
const FilterButtons = ({ category, catID }) => {
  const { showSpinner, users, handleFilter, allToggled } = useContext(
    ProjectContext
  );

  const checkmark = (
    <FontAwesomeIcon
      icon={faCheck}
      className={showSpinner ? "graycheck" : "checkmark"}
    />
  );

  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (allToggled) {
      setChecked(false);
    }
  }, [allToggled]);

  return (
    <button
      disabled={showSpinner ? true : false}
      id={catID}
      className="filterButton"
      onClick={() => {
        handleFilter(catID);
        setChecked(!checked);
      }}
    >
      <div className="box-sm">
        {allToggled ? checkmark : checked ? checkmark : ""}
      </div>
      <p>{category}</p>
    </button>
  );
};

export default FilterButtons;
