import React, { useContext, useEffect } from "react";
import MarketingMap from "./MarketingMap";
import imgshow from "../../Assets/Images/marketing mapa page- hero element.png";
import { ProjectContext } from "../../Context/MainContext";
import Card from "../Card/Card";
import CtaCard from "../CTACards/CtaCard";
import FiltersPC from "../Filters/Filters";
import FiltersMob from "../Filters/FiltersMob";
import Footer from "../Footer/Footer";
import Modal from "../Modal/Modal";
import Nav from "../Nav/Nav";
import Popup from "../Popup/Popup";
import "./NewMap.css";

const NewMap = () => {
  const {
    setHideButton,
    showHire,
    showApply,
    MainItemsShown,
    filterID,
    setShowMore,
    showMore,
    filterMode,
    allToggled,
    setHideLogo,
    setInMap,
    showSpinner,
  } = useContext(ProjectContext);

  useEffect(() => {
    setHideButton(false);
    setHideLogo(false);
    setInMap(true);
  }, []);
  return (
    <>
      <Nav />
      {showApply && <Modal showApply={showApply} show={showApply} />}
      {showHire && <Popup showHire={showHire} show={showHire} />}
      <div className="wrapper-main">
        <div className="map-header">
          <h1>Macedonia Marketing Map</h1>
          <p>
            Провери кои маркетинг експерти и агенции најмногу <br />
            одговарааат за твоите потреби. Бесплатно.
          </p>
          <img src={imgshow} alt="" className="picpic" />
        </div>
        {showSpinner ? (
          <div className="spinnermain">Податоците се вчитуваат.</div>
        ) : (
          <div className="container-map">
            {window.innerWidth < 770 ? <FiltersMob /> : <FiltersPC />}
            <div className="main">
              {showSpinner
                ? ""
                : !MainItemsShown.length && (
                    <p>
                      Во овој момент нема достапни
                      {filterID === 2 ? " компании" : " фриленсери"} од
                      избраните категории!
                    </p>
                  )}

              {MainItemsShown.map((el, i) => {
                return (
                  <Card
                    name={el.name}
                    type={el.TYPE}
                    desc={el.description}
                    email={el.email}
                    link={el.link}
                    key={i}
                    exp={el.experiences}
                    phone={el.phone_number}
                    loc={el.location}
                  />
                );
              })}
              {(filterMode || allToggled) && (
                <p
                  onClick={() => setShowMore(showMore + 6)}
                  className={
                    MainItemsShown.length < showMore ? "hideshow" : "showmore"
                  }
                >
                  Види повеќе
                </p>
              )}
            </div>
          </div>
        )}
      </div>
      <CtaCard showHire={showHire} />
      <Footer />
    </>
  );
};

export default NewMap;
