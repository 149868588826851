import React from 'react'
import styled from 'styled-components';

// import back from '../../Assets/Images/Group_108.png'
import dots from '../../Assets/Images/Repeat_Grid.png'

const Overlay = styled.div`
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 9999;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.1);
`;

const Wrap = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  background-color:white;
`;

const Backdrop = ({ showbackdrop, clicked }) => {
  return showbackdrop ? (
    <Wrap>
      <Overlay onClick={clicked}>
        <img width="100%" height="100%" src={dots} alt="back" />
      </Overlay>
    </Wrap>
  ) : null;
};

export default Backdrop
