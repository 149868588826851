import React from 'react';
import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  margin-top: 5rem;

  @media (max-width: 600px) {
    padding: 0 1rem;
  }
`;

const H1 = styled.h1`
  font-size: 34px;
  font-family: 'Montserrat', sans-serif;
  margin: 40px auto;
  padding: 0;
  text-align: center;

  @media (max-width: 1366px) {
    font-size: 30px;
  }
  @media (max-width: 850px) {
    font-size: 25px;
  }
  @media (max-width: 600px) {
    font-size: 20px;
  }
`;

const Para = styled.p`
  font-size: 18px;
  font-family: 'Montserrat', sans-serif;
  margin: 0 0 10px 0;

  ${({ fotterp }) =>
    fotterp &&
    css`
      color: gray;
      margin-bottom: 5rem;
      line-height: 1.2rem;
    `}

  @media (max-width: 1366px) {
    font-size: 14px;
  }

  @media (max-width: 850px) {
    font-size: 12px;
  }

  @media (max-width: 600px) {
    font-size: 11px;
  }
`;

const A = styled.a`
  font-size: 18px;
  color: blue;
  text-decoration: underline;

  @media (max-width: 1366px) {
    font-size: 14px;
  }

  @media (max-width: 850px) {
    font-size: 12px;
  }

  @media (max-width: 600px) {
    font-size: 11px;
  }
`;

const Content = styled.div`
  text-align: center;
`;

const Input = () => {
  return (
    <Wrapper>
      <Content>
        <H1>Ви благодариме за одвоеното време!</H1>
        <Para>Нашиот тим ќе го направи правиот избор за Вас!</Para>
        <Para>Наскоро ќе добиете порака на вашиот eмаил.</Para>
      </Content>
      <Para fotterp>
        Сакате да научите повеќе за маркетинг? Кликнете на следниот линк&nbsp;
        <A href="/">Едукација</A>
      </Para>
    </Wrapper>
  );
};

export default Input;
