export const hireData = [
  {
    id: 1,
    name: "industries",
    title: "Како би ја опишале вашата индустрија?",
    require: "Задолжително изберете поле *",
    options: [],
    valid: false,
    checked: 0,
    loaded: false,
    main_id: "industry_id",
  },
  {
    id: 2,
    name: "employees_number",
    title: "Големина на компанија (број на вработени)",
    options: [],
    checked: 0,
    loaded: false,

    require: "Задолжително изберете поле *",
    valid: false,
  },
  {
    id: 3,
    name: "partner_type",
    options: [],
    title: "Каков вид на маркетинг партнер(и) барате?",
    checked: 0,
    loaded: false,
    require: "Задолжително изберете поле *",
    valid: false,
    note:
      'Забелешка: Доколку немате строги преференции, изберете "Без преференции" и ќе Ви помогнеме во изборот',
  },
  {
    id: 4,
    name: "services",
    options: [],
    checked: [],
    loaded: false,
    title: "Кои од долунаведените услуги се во ваш интерес?",
    require: "Задолжително изберете поле *",
    valid: false,
    note: "Забелешка: Можете да одберете повеќе од една услуга",
  },
  {
    id: 5,
    options: [],
    name: "budget",
    title: "Колкав е буџетот кој ќе го одвоите за овој проект?",
    checked: 0,
    loaded: false,
    require: "Задолжително изберете поле *",
    valid: false,
    main_id: "budget_id",
  },
  {
    id: 6,
    name: "realisation_time",
    options: [],
    title: "Кога би започнале со реализација?",
    checked: 0,
    loaded: false,
    require: "Задолжително изберете поле *",
    valid: false,
  },
  {
    id: 7,
    options: [],
    name: "searching_stage",
    checked: 0,
    loaded: false,
    title: "До која фаза сте со барањето на кандидати",
    require: "Задолжително изберете поле *",
    valid: false,
  },
];

export const inputValues = {
  name: {
    value: "",
  },
  email: {
    value: "",
  },
  web: {
    value: "",
  },
};
