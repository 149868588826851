import React, { useContext } from "react";
import styled from "styled-components";
import { ProjectContext } from "../../Context/MainContext";
import { Button, Alignbtn } from "../Styled/styled";

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  // max-width: 650px;

  & .error {
    font-size: 14px;
    color: #de0404;
  }

  @media (max-width: 1366px) {
    & .error {
      font-size: 12px;
    }
  }

  @media (max-width: 426px) {
  }
`;
export const Label = styled.label`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 30px;

  @media (max-width: 1366px) {
    margin-bottom: 10px;
  }

  input {
    width: 100%;
    border: none;
    border-bottom: 1px solid gray;
    padding-top: 1rem;
    padding-bottom: 2px;
    font-size: 14px;
    font-family: "Montserrat";
    margin-bottom: 0.5rem;
    &:focus {
      outline: none;
    }
  }
  @media (max-width: 1366px) {
    font-size: 16px;
  }
  @media (max-width: 800px) {
    font-size: 14px;
    text-align: left;
  }

  @media (max-width: 600px) {
    font-size: 13px;
  }

  @media (max-width: 426px) {
    margin-bottom: 15px;
    // margin-bottom: 0;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;

  & .cheading {
    margin-bottom: 2rem;
    text-align: left;
    font-weight: bold;
    margin-left: 0;
  }

  @media (max-width: 850px) {
    margin-top: ${(p) => p.contactmob || "7rem"};
  }
`;

const Contact = ({
  submit,
  showinputs,
  name,
  email,
  web,
  changed,
  valueInput,
  formHireIsSubmit,
  clicked,
  contactmob,
  valueInputApply,
  blur,
  focus,
  location,
  phone,
  desc,
}) => {
  const { showHire, showApply } = useContext(ProjectContext);
  return showHire ? (
    <Wrapper contactmob={contactmob}>
      {showinputs ? <h2 className="cheading">Речиси готово!</h2> : null}
      <Form onSubmit={submit}>
        <Label htmlFor="name">
          {showinputs
            ? "Вашето целосно име / името на вашата компанија *"
            : "Вашето целосно име? *"}
          <input
            type="text"
            name="name"
            id="name"
            value={name}
            onChange={changed}
            onBlur={blur}
            onFocus={focus}
          ></input>
          {formHireIsSubmit && !valueInput["name"].valid ? (
            <p className="error">Внесете го името на Вашата компанија</p>
          ) : null}
        </Label>

        <Label htmlFor="email">
          {showinputs
            ? "Внесете ја вашата email адреса *"
            : "Која е вашата email адреса? *"}
          <input
            type="email"
            name="email"
            id="email"
            value={email}
            onChange={changed}
            onBlur={blur}
            onFocus={focus}
          ></input>
          {formHireIsSubmit && !valueInput["email"].valid ? (
            <p className="error">
              Внесете емаил во следниов формат example@example.something
            </p>
          ) : null}
          {!showinputs &&
          valueInputApply["email"].touched &&
          !valueInputApply["email"].valid ? (
            <p className="error">
              Внесете емаил во следниов формат example@example.something
            </p>
          ) : null}
        </Label>

        <Label htmlFor="web">
          {showinputs
            ? "Внесете ја вашата веб сајт адреса"
            : "Која е вашата веб адреса? *"}
          <input
            type="text"
            name="web"
            id="web"
            value={web}
            onChange={changed}
            onBlur={blur}
            onFocus={focus}
          ></input>
          {formHireIsSubmit && !valueInput["web"].valid ? (
            <p className="error">
              Внесете веб сајт во следниов формат www.example.com{" "}
            </p>
          ) : null}
        </Label>

        {showinputs ? (
          <Alignbtn>
            <Button onClick={clicked}>Потврди</Button>
          </Alignbtn>
        ) : null}
      </Form>
    </Wrapper>
  ) : (
    <Wrapper contactmob={contactmob}>
      {showinputs ? <h2 className="cheading">Речиси готово!</h2> : null}
      <Form onSubmit={submit}>
        <Label htmlFor="name">
          {showinputs
            ? "Вашето целосно име / името на вашата компанија *"
            : "Вашето целосно име? *"}
          <input
            type="text"
            name="name"
            id="name"
            value={name}
            onChange={changed}
            onBlur={blur}
            onFocus={focus}
          ></input>
          {formHireIsSubmit && !valueInput["name"].valid ? (
            <p className="error">Внесете го името на Вашата компанија</p>
          ) : null}
        </Label>
        <Label htmlFor="desc">
          {showinputs
            ? "Краток опис за вас.*"
            : "Внесете краток опис за вас? *"}
          <input
            type="text"
            name="desc"
            id="desc"
            value={desc}
            onChange={changed}
            onBlur={blur}
            onFocus={focus}
          ></input>
          {formHireIsSubmit && !valueInput["desc"].valid ? (
            <p className="error">Внесете опис за вас</p>
          ) : null}
        </Label>
        <Label htmlFor="email">
          {showinputs
            ? "Внесете ја вашата email адреса *"
            : "Која е вашата email адреса? *"}
          <input
            type="email"
            name="email"
            id="email"
            value={email}
            onChange={changed}
            onBlur={blur}
            onFocus={focus}
          ></input>
          {formHireIsSubmit && !valueInput["email"].valid ? (
            <p className="error">
              Внесете емаил во следниов формат example@example.something
            </p>
          ) : null}
          {!showinputs &&
          valueInputApply["email"].touched &&
          !valueInputApply["email"].valid ? (
            <p className="error">
              Внесете емаил во следниов формат example@example.something
            </p>
          ) : null}
        </Label>
        <Label htmlFor="phone">
          {showinputs
            ? "Вашиот телефонски број?*"
            : "Внесете телефонски број? *"}
          <input
            type="text"
            name="phone"
            id="phone"
            value={phone}
            onChange={changed}
            onBlur={blur}
            onFocus={focus}
          ></input>
          {formHireIsSubmit && !valueInput["phone"].valid ? (
            <p className="error">
              Внесете телефонски број во формат +3897*******{" "}
            </p>
          ) : null}
        </Label>
        <Label htmlFor="web">
          {showinputs
            ? "Внесете ја вашата веб сајт адреса"
            : "Која е вашата веб адреса? *"}
          <input
            type="text"
            name="web"
            id="web"
            value={web}
            onChange={changed}
            onBlur={blur}
            onFocus={focus}
          ></input>
          {formHireIsSubmit && !valueInput["web"].valid ? (
            <p className="error">
              Внесете веб сајт во следниов формат www.example.com{" "}
            </p>
          ) : null}
        </Label>
        <Label htmlFor="location">
          {showinputs
            ? "Внесете каде сте стационирани"
            : "Каде сте стационирани? *"}
          <input
            type="text"
            name="location"
            id="location"
            value={location}
            onChange={changed}
            onBlur={blur}
            onFocus={focus}
          ></input>
          {formHireIsSubmit && !valueInput["location"].valid ? (
            <p className="error">
              Внесете веб сајт во следниов формат www.example.com{" "}
            </p>
          ) : null}
        </Label>

        {showinputs ? (
          <Alignbtn>
            <Button onClick={clicked}>Потврди</Button>
          </Alignbtn>
        ) : null}
      </Form>
    </Wrapper>
  );
};
export default Contact;
