export const applydata = [
  {
    id: 1,
    name: "partner_type",
    title: "Дали сте агенција или freelancer?",
    require: "Задолжително изберете поле *",
    valid: false,
    checked: 0,
    loaded: false,
    options: [],
  },
  {
    id: 2,
    name: "services",
    title: "Во која област или маркетинг дејност сте специјализирани?",
    require: "Задолжително изберете поле *",
    note: "Забелешка: Можете да одберете повеќе од една услуга",
    valid: false,
    loaded: false,
    options: [],
    checked: [],
  },
  {
    id: 3,
    name: "experiences",
    title: "Во која бизнис област имате искуство?",
    require: "Задолжително изберете поле *",
    note: "Забелешка: Можете да одберете повеќе од една услуга",
    loaded: false,
    options: [],
    valid: false,
    checked: [],
  },
];
