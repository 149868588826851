const categories = [
  { id: 1, category: "Marketing and Growth Strategies", checked: false },
  { id: 2, category: "Social Search", checked: false },
  { id: 3, category: "Social Ads", checked: false },
  { id: 4, category: "Content and SEO", checked: false },
  { id: 5, category: "Email Marketing", checked: false },
  { id: 6, category: "Social Media", checked: false },
  { id: 7, category: "Influencer Marketing", checked: false },
  { id: 8, category: "Analytics", checked: false },
  { id: 9, category: "Conversion Rate Optimization", checked: false },
  { id: 10, category: "Web Design and Development", checked: false },
  { id: 11, category: "Video Production", checked: false },
  { id: 12, category: "Offline Marketing", checked: false },
  { id: 13, category: "Amazon Management", checked: false },
  { id: 14, category: "Brand Marketing", checked: false },
  { id: 15, category: "PR", checked: false },
];

export default categories;
