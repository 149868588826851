import styled, { css } from "styled-components";

export const Wrap = styled.div`
  width: 86%;
  height: 86%;
  position: fixed;
  background: white;
  z-index: 9999;
  left: 7%;
  top: 7%;
  transform: ${(props) =>
    props.open ? "translateY(0)" : "translateY(-100vh)"};
  opacity: ${(props) => (props.open ? 1 : 0)};
  transition: all 0.3s ease-out;

  @media (max-width: 1150px) {
    width: 90%;
    height: 92vh;
    left: 5%;
    top: 4%;
  }
  @media (max-width: 950px) {
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
  }
`;

export const Cross = styled.div`
  width: 15px;
  height: 15px;
  position: absolute;
  top: 2rem;
  right: 2rem;

  @media (max-width: 1366px) {
    top: 1.5rem;
    right: 1.5rem;
  }

  img {
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
`;

const Svgs = styled.svg`
  width: 100%;
  height: 100%;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  fill: #b7b7b7;

  ${({ drop }) =>
    drop &&
    css`
      width: 18px;
      height: 18px;
    `}
`;

export const Svg = ({ path, id, ...props }) => (
  <Svgs {...props}>
    <use xlinkHref={`${path}#${id}`} />
  </Svgs>
);

export const Toparrow = styled.div`
  width: 30px;
  height: 30px;
  position: absolute;
  top: 2.5rem;
  right: 48%;
  display: ${(p) => (p.showtop ? "none" : "block")};

  @media (max-width: 1366px) {
    top: 1.5rem;
  }

  @media (max-width: 850px) {
    transform: rotate(-90deg);
    top: 48%;
    left: 2%;
  }
`;

export const Bottomarrow = styled.div`
  width: 30px;
  height: 30px;
  position: absolute;
  bottom: 2.5rem;
  right: 48%;
  display: ${(p) => (p.showbottom ? "none" : "block")};

  @media (max-width: 1366px) {
    bottom: 1.5rem;
  }

  @media (max-width: 850px) {
    transform: rotate(-90deg);
    top: 48%;
    right: 2%;
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: ${(props) => props.conwidth || "75%"};
  height: 100%;
  margin: 0 5% 0 auto;
  color: #020d44;

  & .note {
    font-size: 18px;
    margin-bottom: 2rem;
    max-width: 89%;
    text-align: left;
    line-height: 1.4rem;
    margin-left: -7px;
  }

  & .require {
    font-size: 18px;
    margin-bottom: 1rem;
    max-width: 510px;
    text-align: left;
    line-height: 1.2rem;
    color: #de0404;
    margin-left: -7px;
  }

  h2 {
    margin-bottom: 0.8rem;
    margin-left: -7px;
    text-align: left;
    font-weight: bold;
    font-size: 34px;
  }

  & .heading {
    margin-bottom: 0.5rem;
  }

  @media (max-width: 1366px) {
    width: ${(props) => props.conwidth || "72%"};
    h2 {
      margin-bottom: 0.5rem;
      font-size: 30px;
      margin-left: -3px;
    }
    & .note {
      font-size: 14px;
      max-width: 70%;
      line-height: 1.2rem;
      margin-left: -3px;

      @media (max-width: 850px) {
        max-width: 100%;
        font-size: 12px;
      }

      @media (max-width: 600px) {
        font-size: 11px;
        margin-bottom: 1.5rem;
      }
    }
    & .require {
      font-size: 14px;
      margin-left: -3px;

      @media (max-width: 850px) {
        max-width: 100%;
        font-size: 12px;
      }
      @media (max-width: 600px) {
        font-size: 11px;
        margin-bottom: 0.5rem;
      }
    }
  }

  @media (max-width: 850px) {
    width: 73%;
    margin: 0 auto;
    margin-top: ${(p) => (p.mtmodal ? "2.3rem" : undefined)};

    ${({ content }) =>
      content &&
      css`
        justify-content: flex-start;
        margin-top: 8rem;
      `}

    h2 {
      font-size: 25px;
      @media (max-width: 600px) {
        font-size: 18px;
      }
    }
  }
  @media (max-width:376px) {
    margin-top: ${(props) => props.zeromt || "0"};
`;

export const Text = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: ${(props) => props.wrap || "nowrap"};
  height: ${(props) => props.textheight};

  button {
    border: 0;
    background: none;
    text-decoration: underline;
    outline: none;
    margin-right: 0.1rem;
    cursor: pointer;
  }

  @media (max-width: 1366px) {
    height: ${(props) => props.mobheight};
  }

  @media (max-width: 850px) {
    flex-wrap: nowrap;
    height: auto;
  }
`;

export const Inline = styled.div`
  display: flex;
  text-align: left;
  margin: ${(props) => props.margin || undefined};
  justify-content: center;
  align-items: center;

  input {
    margin: 0 1rem 0 0;
    transform: scale(1.8);
  }

  label {
    font-size: 18px;
    font-weight: 400;
    font-size: 22px;
    margin-right: 15px;
  }

  @media (max-width: 1366px) {
    label {
      font-size: 16px;
    }
    input {
      transform: scale(1.5);
    }
  }

  @media (max-width: 850px) {
    label {
      font-size: 14px;
    }
  }

  @media (max-width: 600px) {
    margin-bottom: 7px;
    margin-right: ${(p) => p.mr};
  }

  @media (max-width: 500px) {
    label {
      font-size: 12px;
    }
  }
  @media (max-width: 376px) {
    label {
      font-size: 11px;
    }
`;

export const Stepcircule = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: ${(props) => (props.current ? "#052bf0" : "#d6d6d6")};
  margin-left: -8.4px;
  margin-bottom: ${(p) => p.mbmob || "57.7px"};

  @media (max-width: 1366px) {
    width: 15px;
    height: 15px;
    margin-left: -6px;
    margin-bottom: 40px;
  }

  @media (max-width: 500px) {
    width: 15px;
    height: 15px;
    margin-left: -6px;
    margin-bottom: ${(p) => p.mbmob || "26px"};
  }
`;

export const Stepline = styled.div`
  height: ${(props) => props.multypop * 80}px;
  width: 3px;
  background: #052bf0;
  position: absolute;
  left: 8%;
  top: 0;

  @media (max-width: 1366px) {
    height: ${(props) => props.multypop * 57 || props.multymodal * 60}px;
  }

  @media (max-width: 500px) {
    height: ${(props) => props.multypop * 43}px;
  }

  // @media (max-width: 426px) {
  //   height: ${(props) => props.HEIGHTBIG};
  // }
`;

export const Wrapstep = styled.div`
  height: ${(props) => props.wrapstepheightmob || "560px"};
  width: 3px;
  background: #d6d6d6;
  position: absolute;
  left: 8%;
  top: 50%;
  transform: translateY(-50%);

  @media (max-width: 1366px) {
    height: ${(props) => props.wrapstepheightmob || "170px"};
  }

  @media (max-width: 850px) {
    transform: rotate(-90deg);
    left: 50%;
    top: ${(props) => props.wrapsteptopmob || "-19%"};
  }

  @media (max-width: 500px) {
    height: ${(props) => props.wrapstepheightmob || "300px"};
    top: ${(props) => props.wrapsteptopmob || "-11%"};
  }

  @media (max-width: 376px) {
    top: ${(props) => props.TOPMOB || "-5.5%"};
  }
`;

export const Button = styled.button`
  width: 10rem;
  height: 3.5rem;
  color: white;
  background: #052bf0;
  border: none;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  font-weight: 600;
  font-size: 22px;

  @media (max-width: 1366px) {
    width: 8rem;
    height: 2.8rem;
    font-size: 18px;
  }

  @media (max-width: 600px) {
    width: 7rem;
    height: 2.6rem;
    font-size: 16px;
  }

  &:focus {
    outline: none;
  }
`;

export const Alignbtn = styled.div`
  width: 100%;
  text-align: left;

  @media (max-width: 850px) {
    text-align: center;
  }
`;
