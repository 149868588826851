import {
  faAngleDown,
  faAngleUp,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useState } from "react";
import Draggable from "react-draggable";
import categories from "../../Assets/JS/categories";
import { ProjectContext } from "../../Context/MainContext";
import FilterButtons from "../FilterButtons/FilterButtons";
const FiltersMob = () => {
  const {
    freelancer,
    company,
    allToggled,
    handleTypes,
    handleFilter,
    hideLogo,
    services,
  } = useContext(ProjectContext);

  const [showFilters, setShowFilters] = useState(false);

  const nodeRef = React.useRef(null);
  const checkmarkl = <FontAwesomeIcon icon={faCheck} className="checkmark" />;
  const arrowUp = <FontAwesomeIcon icon={faAngleUp} className="arrowup" />;
  const arrowDown = (
    <FontAwesomeIcon icon={faAngleDown} className="arrowdown" />
  );

  const eventLogger = (e: MouseEvent, data: Object) => {
    console.log("Event: ", e);
    console.log("Data: ", data);
  };

  const handleStart = () => {};

  const handleDrag = (e) => {
    if (showFilters) {
      setShowFilters(false);
    } else {
      setShowFilters(true);
    }

    eventLogger();
  };
  const handleStop = () => {
    if (showFilters) {
      setShowFilters(false);
    } else {
      setShowFilters(true);
    }
  };

  return (
    <React.Fragment>
      {!hideLogo && (
        <Draggable
          nodeRef={nodeRef}
          axis="y"
          handle={showFilters ? ".btnUP" : ".btnDOWN"}
          defaultPosition={{ x: 0, y: 0 }}
          position={null}
          grid={[0, 0]}
          scale={1}
          onStart={handleStart}
          onDrag={handleDrag}
          onStop={handleStop}
        >
          <div
            ref={nodeRef}
            className={showFilters ? "btnUP handle" : "btnDOWN handle"}
            onClick={() => {
              setShowFilters(!showFilters);
            }}
          >
            <p>{showFilters ? arrowDown : arrowUp}</p>
            <p>Филтрираj</p>
          </div>
        </Draggable>
      )}

      <div className="filters">
        <div className={showFilters ? "toggledFilter" : "closedFilter"}>
          <div className="filter-header">
            <div
              className="filterButton btnBB"
              onClick={() => {
                handleTypes(1);
              }}
            >
              <div className="box-sm">{freelancer && checkmarkl}</div>
              <p>Маркетери фриленсери</p>
            </div>
            <div
              className="filterButton btnBB"
              onClick={() => {
                handleTypes(2);
              }}
            >
              <div className="box-sm">{company && checkmarkl}</div>
              <p>Маркетинг компании</p>
            </div>
          </div>
          <div className="filter-buttons">
            <div className="filterButton">
              <div className="box-sm">{allToggled && checkmarkl}</div>
              <p
                onClick={() => {
                  handleFilter("ALL");
                }}
              >
                Прикажи ги сите
              </p>
            </div>
            {services.map((el, i) => {
              if (el.is_filter === 0) {
                return false;
              } else {
                return (
                  <FilterButtons
                    category={el.name}
                    checked={el.checked}
                    key={i}
                    catID={i + 1}
                  />
                );
              }
            })}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default FiltersMob;
