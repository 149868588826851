import React, { createContext, useEffect, useState } from "react";
import categories from "../Assets/JS/categories";
import axios from "axios";
export const ProjectContext = createContext();

// shownUsers e za ID-s na marketerite/agenciite spored koi se prikazani kartickite
let shownUsers = [];
// checked e za koi kategorii see momentalno prikazani
let checked = [];

export const ProjectProvider = (props) => {
  // првично да се сите кликнати
  const [allToggled, setAllToggled] = useState(true);
  // дали е во филтер мод или не
  const [inFilterMode, setInFilterMode] = useState(false);
  // првично да покажи 9 картички, на секој клик понареден на покажи повеќе се додава + 6
  const [showMore, setShowMore] = useState(9);
  // дали да филтрира по маркетери или фирми
  const [freelancer, setfreelacner] = useState(true);
  const [company, setcompany] = useState(true);
  // дали да филтрира по тип и по кој
  const [filterMode, setFilterMode] = useState(false);
  const [filterID, setFilterID] = useState("");

  // filter-category/service

  const [services, setServices] = useState([]);
  // главните containters за users
  const [users, setUsers] = useState([]);
  const [MainItemsShown, setMainItemsShown] = useState([]);

  // хандлери за формите
  const [showHire, setShowHire] = useState(false);
  const [showApply, setShowApply] = useState(false);
  const [hideButton, setHideButton] = useState(false);

  // dali e vo tablet-mobile rezolucija
  const [mobileMode, setMobileMode] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [showSpinner, setShowSpinner] = useState(true);

  const handleShowApply = (e) => {
    e.preventDefault();
    setShowApply(true);
  };
  const handleShowHire = (e) => {
    e.preventDefault();
    setShowHire(true);
  };

  const hideModal = () => {
    setShowHire(false);
    setShowApply(false);
    setHideLogo(false);
  };

  // филтер спрема тип
  const handleTypes = (type) => {
    // активира филтрирање
    setFilterMode(true);
    setFilterID(type);
    if (allToggled) {
      // филтрира спрема сите
      if (type === 1) {
        const itemsToAdd = users.filter((el) => el.marketing_partner.id === type); // наместо users ке треба вашиот array од корисници што e fetchnat
        setMainItemsShown(itemsToAdd);
        setfreelacner(true);
        setcompany(false);
        console.log(itemsToAdd)
      } else {
        // филтрира спрема сите
        const itemsToAdd = users.filter((el) => el.marketing_partner.id === 2); // наместо users ке треба вашиот array од корисници што e fetchnat
        setMainItemsShown(itemsToAdd);
        setcompany(true);
        setfreelacner(false);
      }
    } else if (type === 1) {
      // филтрира по тип од тие што се моментално покажани
      const itemsToAdd = users.filter(
        (el) => el.marketing_partner.id === type && shownUsers.includes(el.id)
      );
      setMainItemsShown(itemsToAdd);
      setfreelacner(true);
      setcompany(false);
    } else {
      // филтрира по тип од тие што се моментално покажани
      const itemsToAdd = users.filter(
        (el) => el.marketing_partner.id === 2 && shownUsers.includes(el.id)
      );
      setcompany(true);
      setfreelacner(false);
      setMainItemsShown(itemsToAdd);
    }
  };

  // филтрира по категорија
  const handleFilter = (category) => {    // ako se klikne na prikazi gi site
    if (category === "ALL") {
      shownUsers = [];
      checked = [];
      setFilterID("");
      setFilterMode(false);
      setMainItemsShown(users.slice(0, 9));
      setAllToggled(true);
      setcompany(true);
      setfreelacner(true);
      setShowMore(9);
      categories.map((el) => {
        el.checked = false;
        return true;
      });
    } else if (checked.includes(category)) {
      // ги наоѓа тие што се кликнати и ги упдејтира при клик дали треба да се внатре или не
      const updateChecked = checked.filter((el) => el !== category);
      checked = updateChecked;
      categories.map((el) => {
        // кога се одселектира ги наоѓа кликнатите и ги прави на false
        if (el.id === category) {
          el.checked = false;
        } else {
          return false;
        }
        return false;
      });

      // се ресетира усерс и се наоѓаат кои треба да се прикажат
      shownUsers = [];
      const result = users.filter((user) =>
        user.marketing_services.find((e) => checked.includes(e.id))
      );
      result.map((el) => {
        if (shownUsers.includes(el.id)) {
          return false;
        } else {
          shownUsers.push(el.id);
          return true;
        }
      });

      const updateUsers = users.filter((el) => shownUsers.includes(el.id));
      setMainItemsShown(updateUsers);

      // ако се одштиклира последната категорија се прави ресет
      if (!shownUsers.length && !checked.length) {
        if (filterMode) {
          // ресет ако треба да се прикажат по тип || ОД ARRAY-OT КАДЕ ШТО СЕ СИТЕ USERS
          const updateUsers = users.filter((el) => el.marketing_partner.id === filterID);
          setMainItemsShown(updateUsers);
          if (!shownUsers.length) {
            setAllToggled(true);
            shownUsers = [];
            checked = [];
            setShowMore(9);
          }
        } else {
          // ресет ако треба да се покажат сите
          shownUsers = [];
          checked = [];
          setAllToggled(true);
          setMainItemsShown(users.slice(0, 9)); // ТУКА ДА СЕ РЕСЕТИРА ОД ARRAY СО СИТЕ USERS
          setShowMore(9);
        }
      } else {
        // да покаже кој users завнисно дали е по тип или не  || OD ARRAYOT SO SITE USERS
        if (filterMode) {
          const updateUsers = users.filter(
            (el) => shownUsers.includes(el.id) && el.marketing_partner.id === filterID
          );
          setMainItemsShown(updateUsers);
        } else {
          // OD ARRAYOT SO SITE USERS
          const updateUsers = users.filter((el) => shownUsers.includes(el.id));
          setMainItemsShown(updateUsers);
        }
      }

      // првичен push
    } else {
      // ако е во филтер по топ
      if (filterMode) {
        checked.push(category);
        setAllToggled(false);

        const result = users.filter((user) =>
          user.marketing_services.find((e) => e.id === category)
        );
        result.map((el) => {
          if (shownUsers.includes(el.id)) {
            return false;
          } else {
            shownUsers.push(el.id);
            return true;
          }
        });

        categories.map((el) => {
          if (el.id === category && !el.checked) {
            el.checked = true;
          } else {
            return false;
          }
          return false;
        });

        const updateUsers = users.filter(
          (el) => shownUsers.includes(el.id) && el.marketing_partner.id === filterID
        );
        setMainItemsShown(updateUsers);
      } else {
        // првичен пуш ако не филтрира по тип
        setAllToggled(false);
        checked.push(category);
        // ги наоѓа корисниците со тој тип кат.
        const result = users.filter((user) =>
          user.marketing_services.find((e) => e.id === category)
        );
        result.map((el) => {
          if (shownUsers.includes(el.id)) {
            return false;
          } else {
            shownUsers.push(el.id);
            return true;
          }
        });

        // кога се одселектира ги наоѓа кликнатите и ги прави на false
        categories.map((el) => {
          if (el.id === category && !el.checked) {
            el.checked = true;
          } else {
            return false;
          }
          return false;
        });

        const updateUsers = users.filter((el) => shownUsers.includes(el.id));
        setMainItemsShown(updateUsers);
      }
    }
  };

  const setMobile = () => {
    if (window.innerWidth < 769) {
      setMobileMode(true);
    } else {
      setMobileMode(false);
    }
  };

  // so menuvanjeto na showMore da se dodadat +9 po default kako pogore ili +6 na sekoj nareden klik
  useEffect(() => {
    setMainItemsShown(users.slice(0, showMore));
  }, [showMore]);
  const [data, setData] = useState([]);

  useEffect(() => {
    window.addEventListener("load", setMobile);
    window.addEventListener("resize", setMobile);

    axios
      .all([
        axios.get(
          "https://marketingmap.brainster.co/api/applicants/approved"
        ),
        axios.get("https://marketingmap.brainster.co/api/services"),
      ])
      .then(
        axios.spread((aplicantsRes, servicesRes) => {
          // console.log(aplicantsRes.data);
          // console.log(servicesRes.data);
          setUsers(aplicantsRes.data); // зачувај array users како што е dummy data od Аssets/JS/users.js
          setMainItemsShown(aplicantsRes.data.slice(0, 9)); // за првично да се покажат 9, после цело време се работи од allData array
          setServices(servicesRes.data);
          setShowSpinner(false);
        })
      )
      .catch((err) => console.log(err.response));

    return () => {
      window.removeEventListener("load", setMobile);
      window.removeEventListener("resize", setMobile);
    };
  }, []);

  const [hideLogo, setHideLogo] = useState(false);
  const [inMap, setInMap] = useState(false);
  const [showZero, setShowZero] = useState(false);
  // што се споделува до сите други компоненти
  const sharedValues = {
    data,
    services,
    showSpinner,
    showZero,
    setShowZero,
    filterMode,
    inMap,
    setInMap,
    hideLogo,
    setHideLogo,
    setFilterMode,
    filterID,
    setFilterID,
    shownUsers,
    checked,
    handleShowApply,
    handleShowHire,
    hideModal,
    showHire,
    setShowHire,
    showApply,
    setShowApply,
    filterOpen,
    setFilterOpen,
    handleTypes,
    handleFilter,
    inFilterMode,
    setInFilterMode,
    freelancer,
    setfreelacner,
    mobileMode,
    company,
    setcompany,
    showMore,
    setShowMore,
    allToggled,
    setAllToggled,
    hideButton,
    setHideButton,
    MainItemsShown,
    setMainItemsShown,
  };

  return (
    <ProjectContext.Provider value={sharedValues}>
      {props.children}
    </ProjectContext.Provider>
  );
};
