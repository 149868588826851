import ReactFullpage from "@fullpage/react-fullpage";
import "fullpage.js/vendors/scrolloverflow"; // Optional. When using scrollOverflow:true
import React, { useContext, useEffect, useState } from "react";
import num0 from "../../Assets/Images/0.png";
import num1 from "../../Assets/Images/01.png";
import num2 from "../../Assets/Images/02.png";
import num3 from "../../Assets/Images/03.png";
import { ProjectContext } from "../../Context/MainContext";
import Footer from "../Footer/Footer";
import Homepage from "../Homepage/Homepage";
import Hero from "../Landing/Hero";
import Modal from "../Modal/Modal";
import Nav from "../Nav/Nav";
import Popup from "../Popup/Popup";
import Services from "../Services/Services";
import VerticalCarousel from "../VerticalCarousel/VerticalCarousel";

const FullpageWrapper = () => {
  const {
    showApply,
    showHire,
    mobileMode,
    setInMap,
    showZero,
    setShowZero,
  } = useContext(ProjectContext);

  const [numIndex, setNumIndex] = useState(0);
  const onLeave = (origin, destination, direction) => {
    if (destination.index === 1 && direction === "down") {
      setShowZero(true);
      setNumIndex(1);
    }
    if (destination.index === 4 && direction === "up") {
      setShowZero(true);
      setNumIndex(3);
    }
    if (destination.index === 1) {
      setNumIndex(1);
    }
    if (destination.index === 2) {
      setNumIndex(2);
    }
    if (destination.index === 3) {
      setNumIndex(3);
    }
    if (origin.index === 4 && direction === "down") {
      setShowZero(false);
      setNumIndex(0);
    }
    if (destination.index === 0 || destination.index > 3) {
      setShowZero(false);
      setNumIndex(0);
    } else {
      setShowZero(true);
    }
    if (destination.index === 1) {
      setNumIndex(1);
    }
    if (destination.index === 2) {
      setNumIndex(2);
    }
    if (destination.index === 3) {
      setNumIndex(3);
    }
  };

  const afterLoad = (origin, destination, direction) => {};

  useEffect(() => {
    setInMap(false);
  }, []);

  const content = (
    <React.Fragment>
      <div id="fullpage-wrapper">
        <div className="section section1">
          <Hero />
        </div>
        <VerticalCarousel />
        <div className="section section5">
          <Services />
        </div>
        <div className="section section6">
          <Homepage />
        </div>
        <div className="section section7">
          <Footer />
        </div>
      </div>
    </React.Fragment>
  );
  const pageWrapper = (
    <ReactFullpage
      onLeave={onLeave}
      afterLoad={afterLoad}
      scrollbar={false}
      scrollingSpeed={300}
      keyboardScrolling={true}
      render={({ state, fullpageApi }) => {
        return content;
      }}
    />
  );
  return (
    <React.Fragment>
      {showZero && (
        <div className="num-bottom">
          <img src={num0} alt="" className="numzero" />
          {numIndex === 1 && <img src={num1} alt="" className="number num1" />}
          {numIndex === 2 && <img src={num2} alt="" className="number" />}
          {numIndex === 3 && <img src={num3} alt="" className="number" />}
        </div>
      )}
      <div>
        {mobileMode && <Nav />}
        {showHire && <Popup showHire={showHire} show={showHire} />}
        {showApply && <Modal showApply={showApply} show={showApply} />}

        {pageWrapper}
      </div>
    </React.Fragment>
  );
};
export default FullpageWrapper;
