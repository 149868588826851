import React, { useContext } from "react";
import { ProjectContext } from "../../Context/MainContext";
import "./Services.css";

function Services() {
  const { setShowHire } = useContext(ProjectContext);

  return (
    <React.Fragment>
      <div className="backgroundServices">
        <div className="services">
          <p className="servicesTitle">
            Сè што ти е потребно за маркетинг на едно место.
          </p>

          <p className="keywords">
            Marketing and growth strategies Search Adds Social Adds Content and
            SEO Email Marketing Social Media Influencer Marketing Analytics
            Conversion Rate Optimization Web Design and Development Video
            production PR Offline Marketing Amazon Management Brand
          </p>
        </div>

        <div className="buttonParent">
          <button className="hireMarketier" onClick={() => setShowHire(true)}>
            Ангажирај Маркетер
          </button>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Services;
