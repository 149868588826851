import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import "./App.css";
import FullpageWrapper from "./component/Fullpage/Fullpage";
import NewMap from "./component/MarketingMap/NewMap";

// menu
// mobile Hiro na sve
// horizontalno
//

function App() {
  return (
    <>
      <Router>
        <Route exact path="/marketing" component={NewMap} />
        <Route exact path="/" component={FullpageWrapper} />
      </Router>
    </>
  );
}

export default App;
