import { useContext } from "react";
import { ProjectContext } from "../../Context/MainContext";
import "./ApplyAsMarketer.css";

function ApplyAsMarketer() {
  const { setShowApply, setHideButton, mobileMode } = useContext(
    ProjectContext
  );

  return (
    <div>
      <li
        className="apply-as-a-marketer"
        onClick={(e) => {
          setShowApply(true);
          if (mobileMode) setHideButton(true);
          e.preventDefault();
        }}
      >
        <a href="/">Аплицирај како маркетер</a>
      </li>
    </div>
  );
}
export default ApplyAsMarketer;
