import React, { useState } from "react";
import FAQ from "../FAQ/FAQ";
import Header from "../Header/Header";
import Group_109 from "../img/Group_109.png";

const Homepage = ({ showHire }) => {
  const [faqs, setfaqs] = useState([
    {
      question: "Дали е бесплатно?",
      answer:
        "Оваа услуга е бесплатна за сите корисници кои се заинтересирани за истата.",
      open: false,
    },
    {
      question: "Сакам подетални информации?",
      answer:
        "Контактирај ја Ања од нашиот тим за вработување и партнерство на anja@brainster.co.",
      open: false,
    },
    {
      question: "Колку наплаќаат маркетинг агенциите и фриленсерите?",
      answer:
        "    Цената може да варира во зависност од проектот и во зависност од маркетерот.  Вообичаено цената за фриленсер изнесува од $25 - $50+ од час, додека цената за агенција може да изнесува од $250 до $1000+ месечно. ",
      open: false,
    },
  ]);

  const toggleFAQ = (index) => {
    setfaqs(
      faqs.map((faq, i) => {
        if (i === index) {
          faq.open = !faq.open;
        } else {
          faq.open = false;
        }

        return faq;
      })
    );
  };
  return (
    <React.Fragment>
      <div className="homepage-wrapper bcgFaq">
        {/* <VerticalCarousel showHire={showHire} />
      <Services showHire={showHire} />
    <VerticalCarousel/> */}

        <Header />
        <div className="faqs">
          {faqs.map((faq, i) => (
            <FAQ faq={faq} index={i} toggleFAQ={toggleFAQ} key={i} />
          ))}
        </div>
        <img src={Group_109} className=" artworkMobile" alt="" />
      </div>
    </React.Fragment>
  );
};

export default Homepage;
