import React from "react";

function Header() {
  return (
    <header className="headerFaq ">
      <h1>
        Често Поставувани <br></br> Прашања
      </h1>

      {/* <img src={Group_108} className=" artwork" alt="" /> */}
    </header>
  );
}

export default Header;
