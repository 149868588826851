import React from "react";
import steps from "../../Assets/JS/steps";
import SlidePage from "./SlidePage";
import "./VerticalCarousel.css";

const VerticalCarousel = () => {
  return (
    <React.Fragment>
      {steps.map((e, i) => {
        return (
          <div className={`section section${e.id}`} key={i}>
            <div className="slide">
              <SlidePage
                key={e.id}
                id={e.num}
                title={e.title}
                text={e.text}
                button={e.button}
                img={e.img}
                num={e.num}
              />
            </div>
          </div>
        );
      })}
    </React.Fragment>
  );
};

export default VerticalCarousel;
