import "./Hero.css";
// import HeroWeb from "./webHero.svg";
import HeroMobile from "./mobileHero.svg";
import HeroTablet from "./heroTablet.png";
import React, { useContext } from "react";
import Nav from "../Nav/Nav";
import { ProjectContext } from "../../Context/MainContext";

function Hero(showHire, showApply) {
  const { setShowApply, setShowHire, mobileMode } = useContext(ProjectContext);

  return (
    <React.Fragment>
      <div>
        {!mobileMode && <Nav />}

        <div className="hero-wrap">
          {/* <img src={HeroWeb} alt="" className="hero" /> */}
          <img src={HeroTablet} alt="" className="heroTablet" />
          <img src={HeroMobile} alt="" className="heroMobile" />
          {/* <div className="Landing"></div> */}
          <div className="AppLanding">
            <h1>
              Сè за Маркетинг <br></br> на Едно Место
            </h1>
            <p>
              Провери кои маркетинг експерти и агенции најмногу одговараат за
              твоите потреби. Бесплатно.
            </p>
            <div className="btn-space">
              <button
                className="buttonHireMarketier"
                onClick={() => setShowHire(true)}
              >
                Ангажирај Маркетер
              </button>
              <button
                className="buttonApplyAsMarketier"
                onClick={() => setShowApply(true)}
              >
                Аплицирај како Маркетер
              </button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Hero;
